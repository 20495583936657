/* eslint-disable sonarjs/no-duplicate-string */
import React from 'react';

import { HEADER_IMAGES } from './headerImages';
import type { SocialProofProps } from '..';
import type { ReviewCardProps } from '../components/ReviewCard';

const REVIEWS: ReviewCardProps[] = [
  {
    headerImage: HEADER_IMAGES.madai,
    name: 'Madai A.',
    date: 'September 2020',
    reviewText: 'It’s the clear skin for @curology.',
  },
  {
    headerImage: HEADER_IMAGES.susie,
    name: 'Susie',
    date: 'December 2020',
    reviewText:
      'What’s foundation? 🤔 I’ve been using @curology for a week and my skin hasn’t been this clear in so long! I highly recommend trying it out. 🤗',
  },
  {
    headerImage: HEADER_IMAGES.kailee,
    name: 'Kailee',
    date: 'November 2020',
  },
  {
    name: 'Hallie',
    date: 'February 2020',
    reviewText:
      'I’ve said it once I’ll say it again: Curology is a miracle and the best thing I’ve ever purchased, hands down. Anyone who has struggled with acne knows how much it sucks and weighs you down every single day. I am over the moon thrilled.',
  },
  {
    headerImage: HEADER_IMAGES.oumou,
    name: 'Oumou',
    date: 'August 2020',
    reviewText:
      'Throughout high school, I have always struggled with acne. I was ready to give up on all acne products when one of my friends suggested Curology. I was skeptical to try it, but knew that this was my last option. In the summer, I choose to wear my hair naturally curly, and the heat and sweat combined with my natural hair products and lotions cause a spike in my hairline acne. Using Curology helped to balance my skin even while taking care of my hair and doing summer activities. Curology gave me the chance to choose both wearing my hair naturally and taking care of my skin which is something that I have always had to choose between in the past.',
  },
  {
    headerImage: HEADER_IMAGES.ysabelle,
    name: 'Ysabelle',
    date: 'October 2020',
  },
  {
    name: 'Liana',
    date: 'December 2019',
    reviewText:
      "I've been using Curology since June and haven't regretted it since. Since day one, it has been improving my acne and my skin. I've had so many compliments about how my skin is glowing and how much cleaer my skin is. I've never been happier with the results like I have with Curology. I am currently still using it and will continue using it until I'm completely satisfied with my skin",
  },
  {
    headerImage: HEADER_IMAGES.lideeya,
    name: 'Lideeya',
    date: 'September 2020',
  },
  {
    name: 'Teruko',
    reviewText:
      "I recommend Curology for several reasons. First, the company aims at making the right formula for your skin type. Second, the products don't make your skin oily or dry. Third, the customer service is outstanding! Lastly, the products are affordable. Overall, I have struggled with acne since middle school and have tried several treatments and nothing had worked. However, after using Curology for one week, I have seen amazing results! My skin is getting clearer and I am feeling more comfortable in my skin. I highly recommend Curology! You will not regret it! 🙂",
  },
  {
    headerImage: HEADER_IMAGES.jaryn,
    name: 'Jaryn',
    date: 'September 2020',
  },
  {
    name: 'Vernon',
    date: 'October 2019',
    reviewText:
      'I never bought skincare products until you came into my life @curology... thank you so much for making it so accessible and giving us all the opportunity to have healthy glowing skin.',
  },
];

export const SOCIAL_PROOF_TEEN_CONTENT: SocialProofProps = {
  header: (
    <span>
      Confident skin starts here. Just ask the internet{' '}
      <span role="img" aria-label="speech bubble">
        💬
      </span>
    </span>
  ),
  reviewsMobile: REVIEWS,
  reviewsDesktop: REVIEWS,
};
