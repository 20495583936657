import React from 'react';

import { HEADER_IMAGES } from './headerImages';
import type { SocialProofProps } from '..';
import type { ReviewCardProps } from '../components/ReviewCard';

const REVIEWS: ReviewCardProps[] = [
  {
    name: '@charlottealger',
    socialIcon: 'instagram',
    reviewText:
      'Love this product! Dark spots vanishing and now have a much more even skin tone. I need very little to no makeup these days. Been using it 4 months and fantastic results!',
  },
  {
    name: 'Katie Wilson',
    socialIcon: 'facebook',
    reviewText:
      'My skin looks overall better. My complexion looks more glowing. I didn’t have any fine lines I was worried about. But the fact I’m 39 and look like I’m in my mid 20s is most likely because of tret',
  },
  {
    name: 'Laura Turner',
    socialIcon: 'facebook',
    reviewText:
      "I love Curology. I'm 45 and use it for anti-aging and my 15 year old uses it for acne and redness. 2 different Rx's custom made for our own and different skin issues. Love it.",
  },

  {
    name: 'Kim Higdon',
    socialIcon: 'facebook',
    reviewText:
      'My skin is smoother—both in texture and coloration—and tighter. I’ve actually simplified my skincare routine, and my skin looks great. I get compliments all the time. I love Curology and recommend it to everyone who comments on my skin. With the trial and very fair pricing, I don’t understand why anyone would not try it.',
  },
  {
    headerImage: HEADER_IMAGES.amyHook,
    name: 'Amy Hook',
    reviewText:
      'I can see and feel the results. My skin just looks better/clearer. But also, I think my jowls look tighter. My skin appears to be firmer. My absolute favorite thing about Curology besides the visible results is the simplicity. I LOVE LOVE LOVE that it 3 products is all it takes. I don’t have to use a cleanser and a toner and a masque and an eye cream and different moisturizers for night/day, etc. Curology is simpler, more affordable, and delivers better results than the last brand I was using (Good Riddance R&F!!!)',
  },
  {
    name: '@missmelanielynn',
    socialIcon: 'instagram',
    reviewText:
      'Yes I love using my cream! I love how the strength of the tretinoin strength is customized, plus there is niacinamide and azelaic acid which helps brighten my skin. I get compliments all the time on my! I’m almost 37 and I have very few fine lines. I love curology! I’ve told so many friends about it and they have signed up too. Such a great service you guys provide.',
  },
  {
    headerImage: HEADER_IMAGES.nancyMcPeak,
    name: 'Nancy McPeak',
    socialIcon: 'facebook',
    reviewText:
      'I noticed a decrease in redness within a week to 10 days. My dark areas (aka age spots) are fading nicely. That took until now to really notice much of a difference. Fine lines and firmness are improving now, too. I am excited to get a little stronger concentration of the tretinoin in my next shipment. I know my skin can handle it and I have already communicated with my PA about it. Very happy with results so far and know that I am going to still see more improvements.',
  },
  {
    name: 'Kate Mills',
    socialIcon: 'facebook',
    reviewText:
      "I started using Curology about two weeks ago. I really like this service and my prescription mix. A little about me, my skin is maturing, I have some dark spots, hyperpigmentation, black heads, pores were large and some wrinkles in the usual areas. I sent my photos, my concerns and my provider created my mix. Very easy to do and it is working. I'm already seeing the dark spots lighten up and the black heads are diminishing. Overall my skin looks so much better. Highly recommend",
  },
  {
    name: 'Laurie Walczak Schleicher',
    socialIcon: 'facebook',
    reviewText:
      'I love that it’s customized specifically for my needs. I don’t deal with acne, but I do deal with combination skin, fine lines and wrinkles, redness, and the occasional breakout. My specialized formula helps address all of these issues. I’m all for customized!',
  },
  {
    name: 'Leslie Sterling',
    socialIcon: 'facebook',
    reviewText:
      'I have been using this for four months. I’m 46 and my skin is clear, smooth and I’m spending less than I was on the mishmosh of stuff I was using before. I’m a total advocate.',
  },
  {
    name: 'Missy Bauman',
    socialIcon: 'facebook',
    reviewText:
      'This company is awesome. My daughter has used it for over a year and her skin is awesome. I started a month ago and see definite improvement in my lines and pores. These are licensed physicians prescribing. The benefit is that the regimen is targeted to each person as an individual. History and photos are required. Then they formulate especially for you.',
  },
  {
    name: 'Marie Phillips',
    socialIcon: 'facebook',
    reviewText:
      'I never struggled with acne, but I was instead looking to lighten some dark spots on my face and proactively prevent signs of aging. At first, I wasn’t sure if I was really seeing a difference in my skin, but I’ve been using the product for 8 months now and I actually see a difference! My skin is unbelievably smooth and soft. And some of my dark spots are lighter than they used to be. The results are definitely not overnight and I’ve changed my formula about three times so far. Each time, it gets better and my provider is super responsive. She’s actually the only person to correctly diagnose the hyperpigmentation on my face! I have been to so many in-person dermatologists who didn’t know what they were doing. Love the subscription element too, it’s easy to just set and forget.Thanks Curology! Looking forward to looking 18 until I’m 73, lol.',
  },
];

export const SOCIAL_PROOF_ANTI_AGING_CONTENT: SocialProofProps = {
  header: <span>Happy skin starts here</span>,
  reviewsMobile: REVIEWS,
  reviewsDesktop: REVIEWS,
};
