import React, { useState } from 'react';

import {
  MainContainer,
  Title,
  PressIconContainer,
  PressIconButton,
  QuotesContainer,
  QuotesList,
  ListItem,
  PressIcon,
} from './ReviewsPress.style';

export type ReviewsPressProps = {
  reviews: {
    name: string;
    SVG: () => JSX.Element;
    text: string;
  }[];
  backgroundColor: string;
};

const moduleName = 'reviewsPress';

export const ReviewsPress = ({
  reviews,
  backgroundColor,
}: ReviewsPressProps) => {
  const [active, setActive] = useState(0);

  return (
    <MainContainer data-module={moduleName} backgroundColor={backgroundColor}>
      <Title>Featured in</Title>
      <PressIconContainer>
        {reviews.map(({ name, SVG }, index) => (
          <React.Fragment key={`${name}`}>
            <PressIconButton
              active={active === index}
              onClick={() => setActive(index)}
              aria-label={`View ${name} quote`}
            >
              <SVG />
            </PressIconButton>
            <PressIcon>
              <SVG />
            </PressIcon>
          </React.Fragment>
        ))}
      </PressIconContainer>
      <QuotesContainer>
        <QuotesList>
          {reviews.map(({ name, text }, index) => (
            <ListItem key={name} active={active === index}>
              {text}
            </ListItem>
          ))}
        </QuotesList>
      </QuotesContainer>
    </MainContainer>
  );
};
