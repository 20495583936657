import React from 'react';

import { HEADER_IMAGES } from './headerImages';
import type { SocialProofProps } from '..';

const REVIEWS = {
  NANCY_MCPEAK: {
    headerImage: HEADER_IMAGES.nancyMcPeakNew,
    name: 'Nancy McPeak',
    socialIcon: 'facebook',
    reviewText:
      'I noticed a decrease in redness within a week to 10 days. My dark areas (aka age spots) are fading nicely. That took until now to really notice much of a difference. Fine lines and firmness are improving now, too. I am excited to get a little stronger concentration of the tretinoin in my next shipment. I know my skin can handle it and I have already communicated with my PA about it. Very happy with results so far and know that I am going to still see more improvements.',
  },
  KATE_MILLS: {
    name: 'Kate Mills',
    socialIcon: 'facebook',
    reviewText:
      "I started using Curology about two weeks ago. I really like this service and my prescription mix. A little about me, my skin is maturing, I have some dark spots, hyperpigmentation, black heads, pores were large and some wrinkles in the usual areas. I sent my photos, my concerns and my provider created my mix. Very easy to do and it is working. I'm already seeing the dark spots lighten up and the black heads are diminishing. Overall my skin looks so much better. Highly recommend",
  },
  AMY_HOOK: {
    headerImage: HEADER_IMAGES.amyHookNew,
    name: 'Amy Hook',
    reviewText:
      'I can see and feel the results. My skin just looks better/clearer. But also, I think my jowls look tighter. My skin appears to be firmer. My absolute favorite thing about Curology besides the visible results is the simplicity. I LOVE LOVE LOVE that it 3 products is all it takes. I don’t have to use a cleanser and a toner and a masque and an eye cream and different moisturizers for night/day, etc. Curology is simpler, more affordable, and delivers better results than the last brand I was using (Good Riddance R&F!!!)',
  },
  KIM_HIGDON: {
    name: 'Kim Higdon',
    socialIcon: 'facebook',
    reviewText:
      'My skin is smoother—both in texture and coloration—and tighter. I’ve actually simplified my skincare routine, and my skin looks great. I get compliments all the time. I love Curology and recommend it to everyone who comments on my skin. With the trial and very fair pricing, I don’t understand why anyone would not try it.',
  },
  MARIE_PHILLIPS: {
    name: 'Marie Phillips',
    socialIcon: 'facebook',
    reviewText:
      'I never struggled with acne, but I was instead looking to lighten some dark spots on my face and proactively prevent signs of aging. At first, I wasn’t sure if I was really seeing a difference in my skin, but I’ve been using the product for 8 months now and I actually see a difference! My skin is unbelievably smooth and soft. And some of my dark spots are lighter than they used to be. The results are definitely not overnight and I’ve changed my formula about three times so far. Each time, it gets better and my provider is super responsive. She’s actually the only person to correctly diagnose the hyperpigmentation on my face! I have been to so many in-person dermatologists who didn’t know what they were doing. Love the subscription element too, it’s easy to just set and forget.Thanks Curology! Looking forward to looking 18 until I’m 73, lol.',
  },
  CHARLOTTE_ALGER: {
    name: '@charlottealger',
    socialIcon: 'instagram',
    reviewText:
      'Love this product! Dark spots vanishing and now have a much more even skin tone. I need very little to no makeup these days. Been using it 4 months and fantastic results!',
  },
} as const;

export const SOCIAL_PROOF_DARK_SPOT_FORMULA_CONTENT: SocialProofProps = {
  header: <span>Happy skin starts here</span>,
  reviewsMobile: [
    REVIEWS.NANCY_MCPEAK,
    REVIEWS.KATE_MILLS,
    REVIEWS.AMY_HOOK,
    REVIEWS.KIM_HIGDON,
    REVIEWS.MARIE_PHILLIPS,
    REVIEWS.CHARLOTTE_ALGER,
  ],
  reviewsDesktop: [
    REVIEWS.NANCY_MCPEAK,
    REVIEWS.KIM_HIGDON,
    REVIEWS.KATE_MILLS,
    REVIEWS.MARIE_PHILLIPS,
    REVIEWS.AMY_HOOK,
    REVIEWS.CHARLOTTE_ALGER,
  ],
  hideViewMoreButtonDesktop: true,
};
