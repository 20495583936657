import { StaticImage } from 'gatsby-plugin-image';

export const AVATAR_IMAGES = {
  breguariv: (
    <StaticImage
      src="../images/avatars/breguariv.jpg"
      alt="Brenda Rivera-Baggott"
      width={40}
    />
  ),
  sweetrhii: (
    <StaticImage
      src="../images/avatars/sweetrhii.jpg"
      alt="Rhiannon Richards"
      width={40}
    />
  ),
  summerskin101: (
    <StaticImage
      src="../images/avatars/summerskin101.jpg"
      alt="Jaiden Ademiluyi"
      width={40}
    />
  ),
  lyfeofsasha: (
    <StaticImage
      src="../images/avatars/lyfeofsasha.jpg"
      alt="@lyfeofsasha"
      width={40}
    />
  ),
  stephanieMelcher: (
    <StaticImage
      src="../images/avatars/stephanieMelcher.jpg"
      alt="Stephanie Melcher"
      width={40}
    />
  ),
  maarisolfigueroa: (
    <StaticImage
      src="../images/avatars/maarisolfigueroa.jpg"
      alt="@maarisolfigueroa"
      width={40}
    />
  ),
  lexaaayyyeee: (
    <StaticImage
      src="../images/avatars/lexaaayyyeee.jpg"
      alt="Alexis Zaragosa"
      width={40}
    />
  ),
  dxadalla: (
    <StaticImage
      src="../images/avatars/dxadalla.jpg"
      alt="@dxadalla"
      width={40}
    />
  ),
  tawshable: (
    <StaticImage
      src="../images/avatars/tawshable.jpg"
      alt="@tawshable"
      width={40}
    />
  ),
  makeupbyjoobie: (
    <StaticImage
      src="../images/avatars/makeupbyjoobie.jpg"
      alt="Juhe Yoon"
      width={40}
    />
  ),
  cheBt: (
    <StaticImage src="../images/avatars/cheBt.jpg" alt="Betty Che" width={40} />
  ),
  raullfigueroa: (
    <StaticImage
      src="../images/avatars/raullfigueroa.jpg"
      alt="@raullfigueroa"
      width={40}
    />
  ),
  skylertsiegel: (
    <StaticImage
      src="../images/avatars/skylertsiegel.jpg"
      alt="@skylertsiegel"
      width={40}
    />
  ),
  emanlifts: (
    <StaticImage
      src="../images/avatars/emanlifts.jpg"
      alt="@emanlifts"
      width={40}
    />
  ),
  owenhalpert: (
    <StaticImage
      src="../images/avatars/owenhalpert.jpg"
      alt="@owenhalpert"
      width={40}
    />
  ),
  alextheaccused: (
    <StaticImage
      src="../images/avatars/alextheaccused.jpg"
      alt="@alextheaccused"
      width={40}
    />
  ),
  jenniferDodson: (
    <StaticImage
      src="../images/avatars/Jennifer_Dodson.jpg"
      alt="Jennifer Dodson"
      width={40}
    />
  ),
  shuacantu: (
    <StaticImage
      src="../images/avatars/shuacantu.jpg"
      alt="@shuacantu"
      width={40}
    />
  ),
  evanHendrickson: (
    <StaticImage
      src="../images/avatars/evan.hendrickson.jpg"
      alt="@evan.hendrickson"
      width={40}
    />
  ),
  ytBabyd: (
    <StaticImage
      src="../images/avatars/yt_babyd.jpg"
      alt="@yt_babyd"
      width={40}
    />
  ),
  dylanKuhn: (
    <StaticImage
      src="../images/avatars/Dylan_Kuhn.jpg"
      alt="Dylan Kuhn"
      width={40}
    />
  ),
  kealiiClapp: (
    <StaticImage
      src="../images/avatars/KealiiClapp.jpg"
      alt="@KealiiClapp"
      width={40}
    />
  ),
  jHeinz69: (
    <StaticImage
      src="../images/avatars/J_Heinz69.jpg"
      alt="@J_Heinz69"
      width={40}
    />
  ),
} as const;
