import { StaticImage } from 'gatsby-plugin-image';

export const HEADER_IMAGES = {
  breguariv: (
    <StaticImage
      src="../images/headers/breguariv.jpg"
      alt="Brenda Rivera-Baggott"
      width={366}
    />
  ),
  sweetrhii: (
    <StaticImage
      src="../images/headers/sweetrhii.jpg"
      alt="Rhiannon Richards"
      width={366}
    />
  ),
  summerskin101: (
    <StaticImage
      src="../images/headers/summerskin101.jpg"
      alt="Jaiden Ademiluyi"
      width={366}
    />
  ),
  liFressita: (
    <StaticImage
      src="../images/headers/liFressita.jpg"
      alt="Eulyn Beltre"
      width={366}
    />
  ),
  lyfeofsasha: (
    <StaticImage
      src="../images/headers/lyfeofsasha.jpg"
      alt="@lyfeofsasha"
      width={366}
    />
  ),
  rachelKochanowski: (
    <StaticImage
      src="../images/headers/rachelKochanowski.jpg"
      alt="Rachel Kochanowski"
    />
  ),
  stephanieMelcher: (
    <StaticImage
      src="../images/headers/stephanieMelcher.jpg"
      alt="Stephanie Melcher"
      width={366}
    />
  ),
  maarisolfigueroa: (
    <StaticImage
      src="../images/headers/maarisolfigueroa.jpg"
      alt="@maarisolfigueroa"
      width={366}
    />
  ),
  lexaaayyyeee: (
    <StaticImage
      src="../images/headers/lexaaayyyeee.jpg"
      alt="Alexis Zaragosa"
      width={366}
    />
  ),
  dxadalla: (
    <StaticImage
      src="../images/headers/dxadalla.jpg"
      alt="@dxadalla"
      width={366}
    />
  ),
  tawshable: (
    <StaticImage
      src="../images/headers/tawshable.jpg"
      alt="@tawshable"
      width={366}
    />
  ),
  makeupbyjoobie: (
    <StaticImage
      src="../images/headers/makeupbyjoobie.jpg"
      alt="Juhe Yoon"
      width={366}
    />
  ),
  cheBt: (
    <StaticImage
      src="../images/headers/cheBt.jpg"
      alt="Betty Che"
      width={366}
    />
  ),
  raullfigueroa: (
    <StaticImage
      src="../images/headers/raullfigueroa.jpg"
      alt="@raullfigueroa"
    />
  ),
  skylertsiegel: (
    <StaticImage
      src="../images/headers/skylertsiegel.jpg"
      alt="@skylertsiegel"
      width={366}
    />
  ),
  emanlifts: (
    <StaticImage
      src="../images/headers/emanlifts.jpg"
      alt="@emanlifts"
      width={366}
    />
  ),
  owenhalpert: (
    <StaticImage
      src="../images/headers/owenhalpert.jpg"
      alt="@owenhalpert"
      width={366}
    />
  ),
  alextheaccused: (
    <StaticImage
      src="../images/headers/alextheaccused.jpg"
      alt="@alextheaccused"
      width={366}
    />
  ),
  shuacantu: (
    <StaticImage
      src="../images/headers/shuacantu.jpg"
      alt="@shuacantu"
      width={366}
    />
  ),
  ytBabyd: (
    <StaticImage
      src="../images/headers/yt_babyd.jpg"
      alt="@yt_babyd"
      width={366}
    />
  ),
  dylanKuhn: (
    <StaticImage
      src="../images/headers/Dylan_Kuhn.jpg"
      alt="Dylan Kuhn"
      width={366}
    />
  ),
  kealiiClapp: (
    <StaticImage
      src="../images/headers/KealiiClapp.jpg"
      alt="@KealiiClapp"
      width={366}
    />
  ),
  jHeinz69: (
    <StaticImage
      src="../images/headers/J_Heinz69.jpg"
      alt="@J_Heinz69"
      width={366}
    />
  ),
  amyHook: (
    <StaticImage
      src="../images/headers/Amy_Hook.jpg"
      alt="Amy Hook"
      width={366}
    />
  ),
  amyHookNew: (
    <StaticImage
      src="../images/headers/Amy_Hook_new.jpg"
      alt="Amy Hook"
      width={366}
    />
  ),
  nancyMcPeak: (
    <StaticImage
      src="../images/headers/Nancy_McPeak.jpg"
      alt="Nancy McPeak"
      width={366}
    />
  ),
  nancyMcPeakNew: (
    <StaticImage
      src="../images/headers/Nancy_McPeak_new.jpg"
      alt="Nancy McPeak"
      width={366}
    />
  ),
  madai: (
    <StaticImage src="../images/headers/madai.jpg" alt="Madai A." width={366} />
  ),
  susie: (
    <StaticImage src="../images/headers/susie.jpg" alt="Susie" width={366} />
  ),
  kailee: (
    <StaticImage src="../images/headers/kailee.jpg" alt="Kailee" width={366} />
  ),
  oumou: (
    <StaticImage src="../images/headers/oumou.jpg" alt="Oumou" width={366} />
  ),
  ysabelle: (
    <StaticImage
      src="../images/headers/ysabelle.jpg"
      alt="Ysabelle"
      width={366}
    />
  ),
  lideeya: (
    <StaticImage
      src="../images/headers/lideeya.jpg"
      alt="Lideeya"
      width={366}
    />
  ),
  jaryn: (
    <StaticImage src="../images/headers/jaryn.jpg" alt="Jaryn" width={366} />
  ),
  michelle: (
    <StaticImage
      src="../images/headers/Michelle.jpg"
      alt="Michelle"
      width={366}
    />
  ),
  isaac: (
    <StaticImage src="../images/headers/Isaac.jpg" alt="Isaac" width={366} />
  ),
  chloe: (
    <StaticImage src="../images/headers/Chloe.jpg" alt="Chloe" width={366} />
  ),
  joshua: (
    <StaticImage src="../images/headers/Joshua.jpg" alt="Joshua" width={366} />
  ),
  gabriela: (
    <StaticImage
      src="../images/headers/Gabriela.jpg"
      alt="Gabriela"
      width={366}
    />
  ),
  diego: (
    <StaticImage src="../images/headers/Diego.jpg" alt="Diego" width={366} />
  ),
  brooklyn: (
    <StaticImage
      src="../images/headers/Brooklyn.jpg"
      alt="Brooklyn"
      width={366}
    />
  ),
  jamal: (
    <StaticImage src="../images/headers/Jamal.jpg" alt="Jamal" width={366} />
  ),
  daphne: (
    <StaticImage src="../images/headers/Daphne.jpg" alt="Daphne" width={366} />
  ),
  nathan: (
    <StaticImage src="../images/headers/Nathan.jpg" alt="Nathan" width={366} />
  ),
  zamonti: (
    <StaticImage
      src="../images/headers/Zamonti.jpg"
      alt="Zamonti"
      width={366}
    />
  ),
  vincent: (
    <StaticImage
      src="../images/headers/Vincent.jpg"
      alt="Vincent"
      width={366}
    />
  ),
  becca: (
    <StaticImage src="../images/headers/Becca.jpg" alt="Becca" width={366} />
  ),
  hector: (
    <StaticImage src="../images/headers/Hector.jpg" alt="Hector" width={366} />
  ),
  oumouMomsOfTeens: (
    <StaticImage
      src="../images/headers/Oumou_momsOfTeens.jpg"
      alt="Oumou"
      width={366}
    />
  ),
} as const;
