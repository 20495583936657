import React from 'react';
import { useLocation } from '@gatsbyjs/reach-router';

import { Nav } from 'components/global/Nav';
import { SEO } from 'components/global/SEO';

import {
  ReviewsPress,
  REVIEWS_PRESS_NEUTRAL_CONTENT,
} from 'components/modules/ReviewsPress';
import { Footer } from 'components/global/Footer';
import { ReviewSeoSchema } from './components/ReviewSeoSchema';
import { ReviewPolicy } from './components/ReviewPolicy';
import {
  SocialProof,
  SOCIAL_PROOF_CORE_CONTENT,
} from 'components/modules/SocialProof';
import { Pricing, PRICING_CORE_CONTENT } from 'components/modules/Pricing';
import { ArticleCarousel } from 'components/modules/ArticleCarousel';
import { fetchRandomArticles } from 'utils/article/fetchArticles';
import useSetLandingPageUrlCookie from 'utils/hooks/useSetLandingPageUrlCookie';

export const ReviewsPage = () => {
  const { pathname } = useLocation();
  const randomArticles = fetchRandomArticles(pathname);
  useSetLandingPageUrlCookie();

  return (
    <React.Fragment>
      <SEO title="Curology Reviews | Skincare Treatments & Results | Curology" />
      <Nav isTransparent />
      <ReviewPolicy />
      <SocialProof {...SOCIAL_PROOF_CORE_CONTENT} />
      <ReviewsPress {...REVIEWS_PRESS_NEUTRAL_CONTENT} />
      <Pricing {...PRICING_CORE_CONTENT} />
      {!randomArticles?.isLoading && randomArticles?.response && (
        <ArticleCarousel content={randomArticles.response} />
      )}
      <Footer />
      <ReviewSeoSchema />
    </React.Fragment>
  );
};
