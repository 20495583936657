import styled from '@emotion/styled';
import { MEDIA_QUERIES, SPACER, Z_SCALE } from 'radiance-ui/lib/constants';
import { REVIEW_CARDS_ONE_COLUMN_WIDTH } from '../SocialProof.style';

export const MainBox = styled.div`
  margin-bottom: ${SPACER.large};

  ${MEDIA_QUERIES.mdUp} {
    margin: 0 auto ${SPACER.large};
    max-width: ${REVIEW_CARDS_ONE_COLUMN_WIDTH}px;
    width: 100%;
    page-break-inside: avoid;
    break-inside: avoid-column;
    display: table;
    position: relative;
    z-index: ${Z_SCALE.e6};
  }
`;

export const HeaderImageContainer = styled.div`
  div {
    // !important is necessary here to override Gatsby Image container styles
    max-width: none !important;
    width: 100%;

    img {
      width: 100%;
    }
  }
`;

export const CardBox = styled.div`
  border: 1px solid ${({ theme }) => theme.COLORS.border};
  padding: ${SPACER.large};
  background: ${({ theme }) => theme.COLORS.white};

  .clamp-lines {
    p {
      margin-top: ${SPACER.medium};
      color: ${({ theme }) => theme.COLORS.primaryTint2};
      white-space: pre-line;
    }

    .clamp-lines__button {
      background: none;
      border: none;
      padding: 0;
      margin: ${SPACER.small} 0 0 0;
      color: ${({ theme }) => theme.COLORS.primaryTint2};
      cursor: pointer;
      border-bottom: 1px solid ${({ theme }) => theme.COLORS.primaryTint2};
      line-height: 1;
    }
  }
`;

export const HeaderBox = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
`;

export const HeaderInfo = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  color: ${({ theme }) => theme.COLORS.primary};
  font-weight: ${({ theme }) => theme.TYPOGRAPHY.fontWeight.bold};

  span {
    display: block;
    color: ${({ theme }) => theme.COLORS.primaryTint2};
    font-weight: ${({ theme }) => theme.TYPOGRAPHY.fontWeight.normal};
    font-size: 14px;
  }
`;

export const AvatarContainer = styled.div`
  margin-right: ${SPACER.medium};

  img {
    border-radius: 50%;
  }
`;
