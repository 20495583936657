export const REVIEW_SEO_SCHEMA_CARDS = [
  {
    username: 'Brenda Rivera-Baggott',
    imageId: 'breguariv',
    icon: 'instagram',
    date: '',
    text: "Thank you, love of my skin's life!",
    readmore: null,
  },
  {
    username: 'Rhiannon Richards',
    imageId: 'sweetrhii',
    date: '',
    socialIcon: 'instagram',
    text: '',
    readmore: null,
  },
  {
    username: 'Jaiden Ademiluyi',
    imageId: 'summerskin101',
    date: '',
    icon: 'instagram',
    text: 'if you have been struggling with acne, this is the way to go!',
    readmore: null,
  },
  {
    username: 'Eulyn Beltre',
    imageId: 'liFressita',
    noAvatar: true,
    date: '',
    icon: 'instagram',
    text: '',
    readmore: null,
  },
  {
    username: '@lyfeofsasha',
    imageId: 'lyfeofsasha',
    date: 'June 22nd, 2020',
    icon: 'instagram',
    text: '',
    readmore: null,
  },
  {
    username: 'Rachel Kochanowski',
    imageId: 'rachelKochanowski',
    noAvatar: true,
    date: '',
    icon: 'instagram',
    text: '',
    readmore: null,
  },
  {
    username: 'Stephanie Melcher',
    imageId: 'stephanieMelcher',
    date: '',
    icon: 'instagram',
    text: '',
    readmore: null,
  },
  {
    username: '@maarisolfigueroa',
    imageId: 'maarisolfigueroa',
    date: 'June 4th, 2019',
    icon: 'instagram',
    text: '',
    readmore: null,
  },
  {
    username: 'Alexis Zaragosa',
    imageId: 'lexaaayyyeee',
    date: '',
    icon: 'instagram',
    text: 'On the real, THIS is what Curology did for my skin',
    readmore: null,
  },
  {
    username: '@dxadalla',
    imageId: 'dxadalla',
    date: 'July 2nd, 2019',
    icon: 'instagram',
    text: '',
    readmore: null,
  },
  {
    username: '@tawshable',
    imageId: 'tawshable',
    date: 'Jan 14th, 2020',
    icon: 'instagram',
    text: 'Curology changed my life, and gave me so much more confidence in myself. I used to wear makeup to hide my insecurities..But with how much it’s changed my skin, I rarely wear makeup at all',
    readmore: null,
  },
  {
    username: 'Juhe Yoon',
    imageId: 'makeupbyjoobie',
    date: '',
    icon: 'instagram',
    text: "Curology update: Not wearing ANY foundation or concealer! My skin has clared up so much ever since the start of my journey! It's been 9 months using #curology and I can't be happier! If you're struggling with acne. I definitley recommend Curology!",
    readmore: null,
  },
  {
    username: 'Betty Che',
    imageId: 'cheBt',
    date: '',
    icon: 'instagram',
    text: "Thank you for everything! I definitley noticed a HUGE difference in my skin. Ofc I used other products along the way but Curology was definitely the main contributor to this amazing change. I don't feel the need to cover up my face anymore when I go out (heart emojies)",
    readmore: null,
  },
] as const;
