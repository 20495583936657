import styled from '@emotion/styled';
import type { ThemeType } from 'radiance-ui/lib/constants';
import { MEDIA_QUERIES, SPACER, GRID } from 'radiance-ui/lib/constants';
import { TYPOGRAPHY_STYLE } from 'radiance-ui';
import { css } from '@emotion/react';

import { desktopOnly, mobileOnly } from 'styles/mixins/displayMobileDesktop';

export const MainContainer = styled.div<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: ${SPACER.x4large} ${SPACER.xlarge};
  text-align: center;
`;

export const Title = styled.h3`
  ${({ theme }) => TYPOGRAPHY_STYLE.title(theme)};
  color: ${({ theme }) => theme.COLORS.primaryTint2};
`;

export const PressIconContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: ${GRID.xlContentMaxWidth};

  ${MEDIA_QUERIES.mdUp} {
    flex-wrap: nowrap;
    margin: ${SPACER.xlarge} auto;
  }
`;

export const pressIconStyles = (theme: ThemeType) => css`
  padding: ${SPACER.large} 0;
  transition: border 0.5s ease-in-out;
  width: 50%;
  border: 0;
  outline: none;
  background: none;

  svg {
    opacity: 0.5;
    fill: ${theme.COLORS.primary};
    width: 100px;
    height: 35px;
  }
`;

export const PressIconButton = styled.button<{ active: boolean }>`
  ${({ theme }) => pressIconStyles(theme)};
  ${desktopOnly()}
  cursor: pointer;
  border-bottom: ${({ active, theme }) =>
    active
      ? `1px solid ${theme.COLORS.primary}`
      : `1px solid ${theme.COLORS.border}`};

  svg {
    opacity: ${({ active }) => (active ? 1 : 0.5)};
  }
`;

export const PressIcon = styled.div`
  ${({ theme }) => pressIconStyles(theme)};
  ${mobileOnly()}
`;

export const QuotesContainer = styled.div`
  display: none;

  ${MEDIA_QUERIES.mdUp} {
    display: block;
  }

  :before {
    content: '\u201C';
    font-size: 48px;
    display: block;
    transform: translateY(25%);
  }

  :after {
    content: '\u201D';
    font-size: 48px;
    display: block;
    transform: translateY(25%);
  }
`;

export const QuotesList = styled.ul`
  height: 76px;
  margin: 0;
  padding: ${SPACER.medium};
  position: relative;
`;

export const ListItem = styled.li<{ active: boolean }>`
  ${({ theme }) => TYPOGRAPHY_STYLE.title(theme)};
  font-style: italic;
  font-weight: ${({ theme }) => theme.TYPOGRAPHY.fontWeight.normal};
  color: ${({ theme }) => theme.COLORS.primaryTint2};
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  margin: 0 auto;
  max-width: ${GRID.smContentMaxWidth};
  position: absolute;
  transition: opacity 0.5s ease-in-out;
  opacity: ${({ active }) => (active ? 1 : 0)};
`;
