import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
} from 'radiance-ui/lib/icons';
import { primaryTheme } from 'radiance-ui/lib/constants';

export const SOCIAL_ICONS = {
  facebook: (
    <FacebookIcon width={16} heigth={16} fill={primaryTheme.COLORS.primary} />
  ),
  twitter: (
    <TwitterIcon width={16} heigth={16} fill={primaryTheme.COLORS.primary} />
  ),
  instagram: (
    <InstagramIcon width={16} heigth={16} fill={primaryTheme.COLORS.primary} />
  ),
} as const;
