import React from 'react';

import {
  MainContainer,
  CenteredContainer,
  header,
  Paragraph,
} from './ReviewPolicy.style';
import { RATINGS_NEUTRAL_CONTENT, Ratings } from 'components/modules/Ratings';

export const ReviewPolicy = () => (
  <MainContainer>
    <CenteredContainer>
      <h1 css={header}>Our Review Policy</h1>
      <Paragraph>
        In the interest of transparency, we do not use incentives to influence
        positive reviews; and because we follow strict privacy policies, we
        never link to a review unless it has been shared <u>publicly</u> by the
        reviewer.
      </Paragraph>
      <Ratings {...RATINGS_NEUTRAL_CONTENT} />
    </CenteredContainer>
  </MainContainer>
);
