/* eslint-disable sonarjs/no-duplicate-string */
import React from 'react';

import { HEADER_IMAGES } from './headerImages';
import type { SocialProofProps } from '..';
import type { ReviewCardProps } from '../components/ReviewCard';

const REVIEWS: ReviewCardProps[] = [
  {
    headerImage: HEADER_IMAGES.michelle,
    name: 'Michelle',
    date: 'March 31st, 2021',
    reviewText:
      'When I started Curology, I had tried everything and was still struggling with my skin. Every cleanser, moisturizer and toner you could think of, I had tried and none of them seemed to work long-term. I was insecure about my skin and felt ashamed of it. I would cake my face in layers of makeup to hide the blemishes. I was breaking out and extremely desperate for a solution until I saw a Curology ad. Eventually, the breakouts started clearing, the scars faded, and my skin was naturally glowy. I love this newfound confidence I gained from using Curology 💜💜💜',
  },
  {
    headerImage: HEADER_IMAGES.isaac,
    name: 'Isaac',
    date: 'Jan 21st, 2021',
  },
  {
    headerImage: HEADER_IMAGES.chloe,
    name: 'Chloe',
    date: 'April 5th, 2021',
    reviewText:
      'I had found Curology about a year and half ago and had immediately found the whole system very unique. I couldn’t wait to give it a go myself. When I first got it, I immediately fell in love with how it made my skin feel. A couple [of] weeks in [and] I could see a difference with my skin. My acne started clearing up and I had finally gained the confidence to leave my house without any makeup. With Curology being such an easy part to fit into my routine, it has changed the way I see skin care for the better.',
  },
  {
    headerImage: HEADER_IMAGES.joshua,
    name: 'Joshua',
    date: 'July 21st, 2020',
  },
  {
    headerImage: HEADER_IMAGES.gabriela,
    name: 'Gabriela',
    date: 'May 26th, 2020',
  },
  {
    headerImage: HEADER_IMAGES.diego,
    name: 'Diego',
    date: 'March 31st, 2021',
    reviewText:
      'My acne was pretty bad, especially on my cheek area. I have sensitive skin so the fact that Curology customizes your formula for your skin type helped a lot. I knew I’d be getting a formula and moisturizer that would be made for my skin type. About two weeks into my treatment, I could see the redness go down and I stopped getting random breakouts. Three months in and my skin was completely clear! I got many questions about how I cleared up my acne and even got people to start using Curology! Currently, I do have some breakouts due to wearing masks, but nothing as close to how it was before.',
  },
  {
    headerImage: HEADER_IMAGES.brooklyn,
    name: 'Brooklyn',
    date: 'April 5th, 2021',
    reviewText:
      'When I was in 5th grade, my skin got really bad and I had a really bad habit of picking my face. I tried [different skin products] throughout the years to help treat my acne, but they didn’t work. I [heard] a lot of people talk about Curology and I also wanted to try it so I showed my mom. It took a bit of time for my face to clear up, but I started to feel happy. I felt the greatest I had ever felt. My face is fully cleared now and I’m happy I tried out Curology.',
  },
  {
    headerImage: HEADER_IMAGES.jamal,
    name: 'Jamal',
    date: 'May 8th, 2020',
  },
  {
    headerImage: HEADER_IMAGES.daphne,
    name: 'Daphne',
    date: 'June 4th, 2020',
  },
  {
    headerImage: HEADER_IMAGES.nathan,
    name: 'Nathan',
    date: 'Oct 14th, 2020',
  },
  {
    headerImage: HEADER_IMAGES.zamonti,
    name: 'Zamonti',
    date: 'April 5th, 2021',
    reviewText:
      'Before Curology, I struggled to find the right stuff for my skin. I struggled with bad breakouts, dark spots and overall, I couldn’t get my skin clear. My face would start to clear up, but it would break out all over again. I started using Curology back in January and I noticed fast results and right now my skin is still clearing up.',
  },
  {
    headerImage: HEADER_IMAGES.vincent,
    name: 'Vincent',
    date: 'May 4th, 2020',
  },
  {
    headerImage: HEADER_IMAGES.becca,
    name: 'Becca',
    date: 'May 14th, 2020',
  },
  {
    headerImage: HEADER_IMAGES.hector,
    name: 'Hector',
    date: 'Oct 14th, 2020',
  },
  {
    headerImage: HEADER_IMAGES.oumouMomsOfTeens,
    name: 'Oumou',
    date: 'August 3rd, 2020',
  },
];

export const SOCIAL_PROOF_MOMS_OF_TEENS_CONTENT: SocialProofProps = {
  header: <span>Confident skin starts here. Just ask the internet.</span>,
  reviewsMobile: REVIEWS,
  reviewsDesktop: REVIEWS,
};
