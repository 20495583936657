import React from 'react';

import { HEADER_IMAGES } from './headerImages';
import { AVATAR_IMAGES } from './avatarImages';
import type { SocialProofProps } from '..';
import type { ReviewCardProps } from '../components/ReviewCard';

const REVIEWS: ReviewCardProps[] = [
  {
    headerImage: HEADER_IMAGES.breguariv,
    avatarImage: AVATAR_IMAGES.breguariv,
    name: 'Brenda Rivera-Baggott',
    socialIcon: 'instagram',
    reviewText: "Thank you, love of my skin's life!",
  },
  {
    headerImage: HEADER_IMAGES.sweetrhii,
    avatarImage: AVATAR_IMAGES.sweetrhii,
    name: 'Rhiannon Richards',
    socialIcon: 'instagram',
  },
  {
    headerImage: HEADER_IMAGES.summerskin101,
    avatarImage: AVATAR_IMAGES.summerskin101,
    name: 'Jaiden Ademiluyi',
    socialIcon: 'instagram',
    reviewText: 'if you have been struggling with acne, this is the way to go!',
  },
  {
    headerImage: HEADER_IMAGES.liFressita,
    name: 'Eulyn Beltre',
    socialIcon: 'instagram',
  },
  {
    headerImage: HEADER_IMAGES.lyfeofsasha,
    avatarImage: AVATAR_IMAGES.lyfeofsasha,
    name: '@lyfeofsasha',
    date: 'June 22nd, 2020',
    socialIcon: 'instagram',
  },
  {
    headerImage: HEADER_IMAGES.rachelKochanowski,
    name: 'Rachel Kochanowski',
    socialIcon: 'instagram',
  },
  {
    headerImage: HEADER_IMAGES.stephanieMelcher,
    avatarImage: AVATAR_IMAGES.stephanieMelcher,
    name: 'Stephanie Melcher',
    socialIcon: 'instagram',
  },
  {
    headerImage: HEADER_IMAGES.maarisolfigueroa,
    avatarImage: AVATAR_IMAGES.maarisolfigueroa,
    name: '@maarisolfigueroa',
    date: 'June 4th, 2019',
    socialIcon: 'instagram',
  },
  {
    headerImage: HEADER_IMAGES.lexaaayyyeee,
    avatarImage: AVATAR_IMAGES.lexaaayyyeee,
    name: 'Alexis Zaragosa',
    socialIcon: 'instagram',
    reviewText: 'On the real, THIS is what Curology did for my skin',
  },
  {
    headerImage: HEADER_IMAGES.dxadalla,
    avatarImage: AVATAR_IMAGES.dxadalla,
    name: '@dxadalla',
    date: 'July 2nd, 2019',
    socialIcon: 'instagram',
  },
  {
    headerImage: HEADER_IMAGES.tawshable,
    avatarImage: AVATAR_IMAGES.tawshable,
    name: '@tawshable',
    date: 'Jan 14th, 2020',
    socialIcon: 'instagram',
    reviewText:
      'Curology changed my life, and gave me so much more confidence in myself. I used to wear makeup to hide my insecurities..But with how much it’s changed my skin, I rarely wear makeup at all',
  },
  {
    headerImage: HEADER_IMAGES.makeupbyjoobie,
    avatarImage: AVATAR_IMAGES.makeupbyjoobie,
    name: 'Juhe Yoon',
    socialIcon: 'instagram',
    reviewText:
      "Curology update: Not wearing ANY foundation or concealer! My skin has clared up so much ever since the start of my journey! It's been 9 months using #curology and I can't be happier! If you're struggling with acne. I definitley recommend Curology!",
  },
  {
    headerImage: HEADER_IMAGES.cheBt,
    avatarImage: AVATAR_IMAGES.cheBt,
    name: 'Betty Che',
    socialIcon: 'instagram',
    reviewText:
      "Thank you for everything! I definitley noticed a HUGE difference in my skin. Ofc I used other products along the way but Curology was definitely the main contributor to this amazing change. I don't feel the need to cover up my face anymore when I go out (heart emojies)",
  },
];

export const SOCIAL_PROOF_CORE_CONTENT: SocialProofProps = {
  header: <span>Confident skin starts here. Just ask the internet.</span>,
  reviewsMobile: REVIEWS,
  reviewsDesktop: REVIEWS,
};
