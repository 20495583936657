import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { SPACER, GRID } from 'radiance-ui/lib/constants';

export const MainContainer = styled.div`
  background-color: ${({ theme }) => theme.COLORS.background};
  padding: ${SPACER.x5large} ${SPACER.xlarge} ${SPACER.x4large};
`;

export const CenteredContainer = styled.div`
  display: block;
  text-align: center;
  padding-top: ${SPACER.x5large};
  padding-bottom: ${SPACER.medium};
  margin: 0 auto;
  max-width: ${GRID.xlContentMaxWidth};
`;

export const header = css`
  margin-top: ${SPACER.xlarge};
  margin-bottom: ${SPACER.large};
`;

export const Paragraph = styled.p`
  max-width: 900px;
  margin: auto;
`;
