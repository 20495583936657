import styled from '@emotion/styled';
import { MEDIA_QUERIES, SPACER, Z_SCALE } from 'radiance-ui/lib/constants';
import { Button, TYPOGRAPHY_STYLE } from 'radiance-ui';

import { LOCAL_CONTENT_MAX_WIDTH, LOCAL_SPACING } from 'styles/constants';

export const REVIEW_CARDS_ONE_COLUMN_WIDTH = 366;
const REVIEW_CARDS_TWO_COLUMNS_WIDTH = 748;

export const MainBox = styled.div<{
  showAllCards: boolean;
  hideViewMoreButtonDesktop: boolean;
  hideViewMoreButtonMobile: boolean;
  backgroundColor?: string;
}>`
  background: ${({ theme, backgroundColor }) =>
    backgroundColor ?? theme.COLORS.white};
  overflow-y: hidden;
  transition: max-height 0.6s ease-in;
  max-height: ${({ showAllCards, hideViewMoreButtonMobile }) =>
    showAllCards || hideViewMoreButtonMobile ? '6800px' : '1500px'};

  ${MEDIA_QUERIES.mdUp} {
    max-height: ${({ showAllCards, hideViewMoreButtonDesktop }) =>
      showAllCards || hideViewMoreButtonDesktop ? '6800px' : '1500px'};
  }
`;

export const InnerBox = styled.div`
  max-width: ${LOCAL_CONTENT_MAX_WIDTH};
  margin: auto;
  padding: ${SPACER.x4large} ${SPACER.large} ${SPACER.x4large};

  ${MEDIA_QUERIES.mdUp} {
    padding: 120px ${LOCAL_SPACING.desktopMainPadding};
    position: relative;
  }
`;

export const Header = styled.h2`
  ${({ theme }) => TYPOGRAPHY_STYLE.heading(theme)};

  ${MEDIA_QUERIES.mdUp} {
    ${({ theme }) => TYPOGRAPHY_STYLE.display(theme)};
    font-family: ${({ theme }) => theme.FONTS.headerFont};
    font-weight: ${({ theme }) => theme.TYPOGRAPHY.fontWeight.normal};
    text-align: center;

    span {
      font-family: ${({ theme }) => theme.FONTS.baseFont};
      font-weight: ${({ theme }) => theme.TYPOGRAPHY.fontWeight.bold};
    }
  }
`;

export const CardsBox = styled.div`
  ${MEDIA_QUERIES.mdUp} {
    width: 100%;
    position: relative;
    column-count: 1;
  }

  ${MEDIA_QUERIES.lgUp} {
    width: ${REVIEW_CARDS_TWO_COLUMNS_WIDTH}px;
    column-count: 2;
    margin: 0 auto;
  }

  ${MEDIA_QUERIES.xlUp} {
    width: 100%;
    column-count: 3;
  }
`;

export const ViewMore = styled(Button)<{
  hideOnMobile: boolean;
  hideOnDesktop: boolean;
  showAllCards: boolean;
}>`
  display: ${({ showAllCards, hideOnMobile }) =>
    showAllCards === true || hideOnMobile === true ? 'none' : 'block'};
  height: 69px;
  border-top: 1px solid ${({ theme }) => theme.COLORS.defaultBorder};
  border-bottom: 1px solid ${({ theme }) => theme.COLORS.defaultBorder};
  border-radius: 0;

  ${MEDIA_QUERIES.mdUp} {
    display: ${({ showAllCards, hideOnDesktop }) =>
      showAllCards === true || hideOnDesktop === true ? 'none' : 'block'};
    height: 80px;
    position: relative;
    z-index: ${Z_SCALE.e6};
  }
`;

export const Disclaimer = styled.div`
  ${({ theme }) => TYPOGRAPHY_STYLE.label(theme)};
  color: ${({ theme }) => theme.COLORS.primaryTint2};
  margin: ${SPACER.medium} 0 ${SPACER.small};

  ${MEDIA_QUERIES.mdUp} {
    margin: ${SPACER.xlarge} auto ${SPACER.small};
    width: ${REVIEW_CARDS_ONE_COLUMN_WIDTH}px;
  }

  ${MEDIA_QUERIES.lgUp} {
    width: ${REVIEW_CARDS_TWO_COLUMNS_WIDTH}px;
  }

  ${MEDIA_QUERIES.xlUp} {
    width: 100%;
  }
`;
