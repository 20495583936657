import React from 'react';

import type { SocialProofProps } from '..';
import type { ReviewCardProps } from '../components/ReviewCard';

const REVIEWS: ReviewCardProps[] = [
  {
    name: 'Kim H.',
    socialIcon: 'facebook',
    reviewText:
      'My skin is smoother, both in texture and coloration, and tighter. I’ve actually simplified my skin care routine and my skin looks great.',
  },
  {
    name: 'Leslie S.',
    socialIcon: 'facebook',
    reviewText:
      'I’m 46 and my skin is clear, smooth and I’m spending less than I was on the mishmosh of stuff I was using before.',
  },
  {
    name: 'Laurie W.',
    socialIcon: 'facebook',
    reviewText: 'I love that it’s customized specifically for my needs.',
  },
  {
    name: 'Marie P.',
    socialIcon: 'facebook',
    reviewText:
      'I’ve been using the product for 8 months now and I actually see a difference! My skin is unbelievably smooth and soft.',
  },
  {
    name: 'Amy H.',
    reviewText:
      'My absolute favorite thing about Curology besides the visible results is the simplicity. I LOVE LOVE LOVE that it 3 products is all it takes.',
  },
  {
    name: '@charlottealger',
    socialIcon: 'instagram',
    reviewText:
      'I need very little to no makeup these days. Been using it 4 months and fantastic results!',
  },
];

export const SOCIAL_PROOF_ANTI_AGING_MARKETING_SAFE_CONTENT: SocialProofProps =
  {
    header: <span>Happy skin starts here</span>,
    reviewsMobile: REVIEWS,
    reviewsDesktop: REVIEWS,
    hideViewMoreButtonDesktop: true,
    hideViewMoreButtonMobile: true,
  };
