import React, { useState } from 'react';
import { useTheme } from '@emotion/react';

import { mobileOnly, desktopOnly } from 'styles/mixins/displayMobileDesktop';
import { trackEvent } from 'utils/metricsTracking';

import {
  MainBox,
  InnerBox,
  Header,
  Disclaimer,
  CardsBox,
  ViewMore,
} from './SocialProof.style';
import { ReviewCard, type ReviewCardProps } from './components/ReviewCard';

export type SocialProofProps = {
  header: React.ReactNode;
  reviewsMobile: ReviewCardProps[];
  reviewsDesktop: ReviewCardProps[];
  hideViewMoreButtonDesktop?: boolean;
  hideViewMoreButtonMobile?: boolean;
  backgroundColor?: string;
};

const moduleName = 'socialProof';

export const SocialProof = ({
  header,
  reviewsMobile,
  reviewsDesktop,
  hideViewMoreButtonDesktop,
  hideViewMoreButtonMobile,
  backgroundColor,
}: SocialProofProps) => {
  const [showAllCards, setShowAllCards] = useState(false);
  const theme = useTheme();

  const viewMoreClickHandler = () => {
    setShowAllCards(!showAllCards);
    trackEvent('Module Interaction', {
      path: window.location.pathname,
      'Module Name': `Social Proof`,
      'Module Element Section': 'View More Button',
      'Module Element Interaction Type': 'Click',
    });
  };

  return (
    <React.Fragment>
      <MainBox
        data-module={moduleName}
        showAllCards={showAllCards}
        hideViewMoreButtonDesktop={!!hideViewMoreButtonDesktop}
        hideViewMoreButtonMobile={!!hideViewMoreButtonMobile}
        backgroundColor={backgroundColor}
      >
        <InnerBox>
          <Header>{header}</Header>
          <Disclaimer>Results may vary.</Disclaimer>
          <CardsBox css={mobileOnly()}>
            {reviewsMobile.map((review) => (
              <ReviewCard {...review} key={review.name} />
            ))}
          </CardsBox>
          <CardsBox css={desktopOnly()}>
            {reviewsDesktop.map((review) => (
              <ReviewCard {...review} key={review.name} />
            ))}
          </CardsBox>
        </InnerBox>
      </MainBox>
      <ViewMore
        onClick={viewMoreClickHandler}
        showAllCards={showAllCards}
        hideOnDesktop={!!hideViewMoreButtonDesktop}
        hideOnMobile={!!hideViewMoreButtonMobile}
        buttonType="tertiary"
        buttonColor={theme.COLORS.primaryTint1}
        isFullWidth
      >
        View More
      </ViewMore>
    </React.Fragment>
  );
};
