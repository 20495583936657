import React from 'react';

import { AVATAR_IMAGES } from './avatarImages';
import { HEADER_IMAGES } from './headerImages';
import type { SocialProofProps } from '..';
import type { ReviewCardProps } from '../components/ReviewCard';

const REVIEWS: ReviewCardProps[] = [
  {
    headerImage: HEADER_IMAGES.raullfigueroa,
    avatarImage: AVATAR_IMAGES.raullfigueroa,
    name: '@raullfigueroa',
    date: 'Feb 26th, 2019',
    socialIcon: 'instagram',
    reviewText:
      'This is honestly so crazy, thank you Curology for saving my skin',
  },
  {
    headerImage: HEADER_IMAGES.breguariv,
    avatarImage: AVATAR_IMAGES.breguariv,
    name: 'Brenda Rivera-Baggott',
    socialIcon: 'instagram',
    reviewText: "Thank you, love of my skin's life!",
  },
  {
    headerImage: HEADER_IMAGES.alextheaccused,
    avatarImage: AVATAR_IMAGES.alextheaccused,
    name: '@alextheaccused',
    date: 'Jan 6th, 2019',
    socialIcon: 'instagram',
    reviewText:
      'Curology has been amazing! I have tried multiple products for acne over the past seven years, with nothing working. I had very little confidence then, but now I have much more. I thas been only a litle over a month, but my face has cleared up with fewer breakouts!',
  },
  {
    headerImage: HEADER_IMAGES.lyfeofsasha,
    avatarImage: AVATAR_IMAGES.lyfeofsasha,
    name: '@lyfeofsasha',
    date: 'June 22nd, 2020',
    socialIcon: 'instagram',
  },
  {
    headerImage: HEADER_IMAGES.dylanKuhn,
    avatarImage: AVATAR_IMAGES.dylanKuhn,
    name: 'Dylan Kuhn',
    date: 'Apr 9th, 2019',
    socialIcon: 'facebook',
    reviewText:
      'Just wanted to share my journey with you guys. I cannot thank you enough for these results i struggled with acne for years and nothing worked and this was the only thing that did, i am so grateful for curology.',
  },
  {
    headerImage: HEADER_IMAGES.dxadalla,
    avatarImage: AVATAR_IMAGES.dxadalla,
    name: '@dxadalla',
    date: 'July 2nd, 2019',
    socialIcon: 'instagram',
  },
  {
    headerImage: HEADER_IMAGES.skylertsiegel,
    avatarImage: AVATAR_IMAGES.skylertsiegel,
    name: '@skylertsiegel',
    date: 'Nov 21th, 2018',
    socialIcon: 'instagram',
    reviewText:
      'I have struggled with acne since I was 12 years old. At first, it was a few blemishes here and there, but as I became older those few blemishes turned into a patch—which turned into all over. I tried multiple products, prescribed medications and creams, and even other well-known acne-fighting formulas. I saw little to no progress and was about to give up on trying. That’s when I saw an ad for Curology. I said why not and decided to try one more product. At first, I didn’t see results, and then little by little I began seeing clearer skin. I was so excited that I began telling everyone about it, and everyone started to see a difference. I am so excited about my results and believe anyone should try it. It does amazing stuff for your skin! Definitely would recommend!',
  },
  {
    avatarImage: AVATAR_IMAGES.jenniferDodson,
    name: 'Jennifer Dodson',
    socialIcon: 'facebook',
    reviewText:
      'My son (age 13) has had very bad acne. We’ve tried everything just short of going to the dermatologist, but nothing has worked. He has been using this product for a week and his acne is close to being completely gone! Kids at school are noticing and he’s so happy. Thank you for such an amazing product. I can’t wait to send in before and after photos!',
  },
  {
    headerImage: HEADER_IMAGES.rachelKochanowski,
    name: 'Rachel Kochanowski',
    socialIcon: 'instagram',
  },
];

export const SOCIAL_PROOF_NEUTRAL_CONTENT: SocialProofProps = {
  header: <span>Real people, real stories</span>,
  reviewsMobile: REVIEWS,
  reviewsDesktop: REVIEWS,
};
