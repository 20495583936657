import React from 'react';

import logo from 'images/seo/curology-square.png';
import coreImage from 'images/seo/core-og-image.jpg';
import { CUSTOM_FORMULA_PRICE } from 'consts/copy';
import { REVIEW_SEO_SCHEMA_CARDS } from './ReviewSeoSchema.content';

export const ReviewSeoSchema = () => {
  const reviews = REVIEW_SEO_SCHEMA_CARDS.map((card) => ({
    '@type': 'Review',
    datePublished: new Date(card.date),
    description: card.text,
    author: {
      '@type': 'Person',
      name: card.username,
    },
    reviewRating: {
      '@type': 'Rating',
      bestRating: '5',
      ratingValue: '5',
      worstRating: '1',
    },
  }));

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: `{
        "@context": "http://schema.org/",
        "@type": "Product",
        "@id": "https://curology.com/product/curology-acne-treatment",
        "name" : "Curology Acne Treatment",
        "brand" : "Curology",
        "description" : "Acne treatment that works, customized for you",
        "logo": "${logo}",
        "image": "${coreImage}",
        "category": "Acne Treatment",
        "url": "https://curology.com/skincare/reviews",
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.7",
          "bestRating": "5",
          "ratingCount": "1439",
          "reviewCount": "1439"
        },
        "offers": {
          "@type": "Offer",
          "price": ${CUSTOM_FORMULA_PRICE},
          "priceCurrency": "USD",
          "availability": "http://schema.org/InStock"
        },
        "review": ${JSON.stringify(reviews)}
      }`,
      }}
    />
  );
};
