import React from 'react';
import { primaryTheme } from 'radiance-ui/lib/constants';

import { HEADER_IMAGES } from './headerImages';
import { AVATAR_IMAGES } from './avatarImages';
import type { SocialProofProps } from '..';
import type { ReviewCardProps } from '../components/ReviewCard';

const REVIEWS: ReviewCardProps[] = [
  {
    headerImage: HEADER_IMAGES.raullfigueroa,
    avatarImage: AVATAR_IMAGES.raullfigueroa,
    name: '@raullfigueroa',
    date: 'Feb 26th, 2019',
    socialIcon: 'instagram',
    reviewText:
      'This is honestly so crazy, thank you Curology for saving my skin',
  },
  {
    headerImage: HEADER_IMAGES.skylertsiegel,
    avatarImage: AVATAR_IMAGES.skylertsiegel,
    name: '@skylertsiegel',
    date: 'Nov 21th, 2018',
    socialIcon: 'instagram',
    reviewText:
      'I have struggled with acne since I was 12 years old. At first, it was a few blemishes here and there, but as I became older those few blemishes turned into a patch—which turned into all over. I tried multiple products, prescribed medications and creams, and even other well-known acne-fighting formulas. I saw little to no progress and was about to give up on trying. That’s when I saw an ad for Curology. I said why not and decided to try one more product. At first, I didn’t see results, and then little by little I began seeing clearer skin. I was so excited that I began telling everyone about it, and everyone started to see a difference. I am so excited about my results and believe anyone should try it. It does amazing stuff for your skin! Definitely would recommend!',
  },
  {
    headerImage: HEADER_IMAGES.emanlifts,
    avatarImage: AVATAR_IMAGES.emanlifts,
    name: '@emanlifts',
    date: 'Jun 26th, 2018',
    socialIcon: 'instagram',
    reviewText:
      'Omg!!! Your product is amazing!! I have been battling with acne for almost 10 years now! I’ve been using yours for a month and I’ve seen wonders!',
  },
  {
    headerImage: HEADER_IMAGES.owenhalpert,
    avatarImage: AVATAR_IMAGES.owenhalpert,
    name: '@owenhalpert',
    date: 'Mar 19th, 2019',
    socialIcon: 'instagram',
    reviewText:
      'January 2019 to March 2019.... fastest improvement I’ve ever sene',
  },
  {
    headerImage: HEADER_IMAGES.alextheaccused,
    avatarImage: AVATAR_IMAGES.alextheaccused,
    name: '@alextheaccused',
    date: 'Jan 6th, 2019',
    socialIcon: 'instagram',
    reviewText:
      'Curology has been amazing! I have tried multiple products for acne over the past seven years, with nothing working. I had very little confidence then, but now I have much more. I thas been only a litle over a month, but my face has cleared up with fewer breakouts!',
  },
  {
    avatarImage: AVATAR_IMAGES.jenniferDodson,
    name: 'Jennifer Dodson',
    socialIcon: 'facebook',
    reviewText:
      'My son (age 13) has had very bad acne. We’ve tried everything just short of going to the dermatologist, but nothing has worked. He has been using this product for a week and his acne is close to being completely gone! Kids at school are noticing and he’s so happy. Thank you for such an amazing product. I can’t wait to send in before and after photos!',
  },
  {
    headerImage: HEADER_IMAGES.shuacantu,
    avatarImage: AVATAR_IMAGES.shuacantu,
    name: '@shuacantu',
    date: 'Apr 3rd, 2019',
    socialIcon: 'instagram',
    reviewText:
      'Curology has worked literal miracles on my face. THIS STUFF IS SENT FROM UP ABOVE',
  },
  {
    avatarImage: AVATAR_IMAGES.evanHendrickson,
    name: '@evan.hendrickson',
    socialIcon: 'instagram',
    reviewText:
      'I never had "terrible" skin, but I remember feeling so self conscious of how oily and rough my skin felt and looked in photos. After only 6 months of using Curology, not only have my number of breakouts become fewer and far between, but I can tell such a difference in its texture and complexion. I never would have felt confident enough to take pictures like these, especially the last. Thank you so much, Curology! (Sorry I don’t have any before photos, but the past is the past)',
  },
  {
    headerImage: HEADER_IMAGES.ytBabyd,
    avatarImage: AVATAR_IMAGES.ytBabyd,
    name: '@yt_babyd',
    date: 'Mar 27th, 2019',
    socialIcon: 'instagram',
    reviewText:
      'I’d just like to express my gratitude to you guys at Curology for giving me my confidence back! I’ve never felt better, just from using the night cream you guys prescribed me. After trial and error, I finally came across something that works! Thank you again for what you do.',
  },
  {
    headerImage: HEADER_IMAGES.dylanKuhn,
    avatarImage: AVATAR_IMAGES.dylanKuhn,
    name: 'Dylan Kuhn',
    date: 'Apr 9th, 2019',
    socialIcon: 'facebook',
    reviewText:
      'Just wanted to share my journey with you guys. I cannot thank you enough for these results i struggled with acne for years and nothing worked and this was the only thing that did, i am so grateful for curology.',
  },
  {
    headerImage: HEADER_IMAGES.kealiiClapp,
    avatarImage: AVATAR_IMAGES.kealiiClapp,
    name: '@KealiiClapp',
    date: 'Apr 15th, 2019',
    socialIcon: 'twitter',
  },
  {
    headerImage: HEADER_IMAGES.jHeinz69,
    avatarImage: AVATAR_IMAGES.jHeinz69,
    name: '@J_Heinz69',
    date: 'May 15th, 2019',
    socialIcon: 'twitter',
    reviewText:
      'I am very pleased with my results! Thank you guys so much for helping me out I was so insecure before and you guys gave me my confidence back!',
  },
];

export const SOCIAL_PROOF_MALE_CONTENT: SocialProofProps = {
  header: <span>Custom, simple, smart. Just ask the internet.</span>,
  reviewsMobile: REVIEWS,
  reviewsDesktop: REVIEWS,
  backgroundColor: primaryTheme.COLORS.background,
};
