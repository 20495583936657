import { primaryTheme } from 'radiance-ui/lib/constants';

import { ReactComponent as BazaarSVG } from '../images/pressBazaar.svg';
import { ReactComponent as BustleSVG } from '../images/pressBustle.svg';
import { ReactComponent as CoveteurSVG } from '../images/pressCoveteur.svg';
import { ReactComponent as InstyleSVG } from '../images/pressInstyle.svg';
import { ReactComponent as ShapeSVG } from '../images/pressShape.svg';
import { ReactComponent as TeenvogueSVG } from '../images/pressTeenvogue.svg';
import type { ReviewsPressProps } from '..';

export const REVIEWS_PRESS_NEUTRAL_CONTENT: ReviewsPressProps = {
  reviews: [
    {
      name: 'coveteur',
      SVG: CoveteurSVG,
      text: 'It’s easy as uploading a no-makeup selfie, and in just a few days you have a customized cream delivered right to your door.',
    },
    {
      name: 'shape',
      SVG: ShapeSVG,
      text: 'It’s the solution to all your sucky skin problems.',
    },
    {
      name: 'instyle',
      SVG: InstyleSVG,
      text: 'This Website Could Completely Transform Your Skin.',
    },
    {
      name: 'bustle',
      SVG: BustleSVG,
      text: 'They’ll pack everything your skin needs into a single bottle to streamline your routine and make it more effective.',
    },
    {
      name: 'teenvogue',
      SVG: TeenvogueSVG,
      text: 'A hot new... company on the pimple-fighting scene.',
    },
    {
      name: 'bazaar',
      SVG: BazaarSVG,
      text: 'This changes everything.',
    },
  ],
  backgroundColor: primaryTheme.COLORS.background,
};
