import React from 'react';

import {
  MainBox,
  CardBox,
  HeaderBox,
  HeaderInfo,
  AvatarContainer,
  HeaderImageContainer,
} from './ReviewCard.style';
import { SOCIAL_ICONS } from '../content/socialIcons';

export type ReviewCardProps = {
  name: string;
  headerImage?: React.ReactNode;
  avatarImage?: React.ReactNode;
  date?: string;
  socialIcon?: 'facebook' | 'instagram' | 'twitter';
  reviewText?: string;
};

export const ReviewCard = ({
  headerImage,
  avatarImage,
  name,
  date,
  socialIcon,
  reviewText,
}: ReviewCardProps) => (
  <MainBox>
    {headerImage && <HeaderImageContainer>{headerImage}</HeaderImageContainer>}
    <CardBox>
      <HeaderBox>
        <HeaderInfo>
          {avatarImage && <AvatarContainer>{avatarImage}</AvatarContainer>}
          <div>
            {name} <span>{date}</span>
          </div>
        </HeaderInfo>
        {socialIcon && SOCIAL_ICONS[socialIcon]}
      </HeaderBox>
      {reviewText}
    </CardBox>
  </MainBox>
);
